import React, { Component } from 'react';
import { connect } from 'react-redux';
import AdminContainer from './AdminContainer';
import BotItem from './elements/BotItem';
import server from '../api/server';
import { setSearchResults } from "../actions";
import moment from "moment";
import AddPremiumModal from './elements/AddPremiumModal';
import MoveSubscriptionModal from './elements/MoveSubscriptionModal';
import history from '../history';
import MoveAllModal from './elements/MoveAllModal';

export class Search extends Component {
    constructor(props) {


        super(props);

        this.state = {
            bot_id: "",
            user_id: "",
            email: "",
            sub_id: "",
            sub_email: "",
            loading: false,
            openBot: "",
            subscription: {},

            showAddPremiumModal: false
        };
    }

    componentDidMount() {
        if (this.props.user.admin_perms.search != true) {
            history.push('/');
        }
    }

    search = async () => {
        this.setState({ loading: true });
        var response = await server.post("/admin/search", this.state);

        if (response && response.data && 'user' in response.data) {
            this.props.setSearchResults(response.data);
        } else {
            // No results
        }
        this.setState({ loading: false });
    };

    renderBots = () => {
        var bots = [];
        this.props.search.bots.forEach((bot, index) => {
            bots.push(<BotItem index={index} onClick={() => {
                if (this.state.openBot == bot.bot_id) {
                    this.setState({ openBot: "" });
                } else {
                    this.setState({ openBot: bot.bot_id });
                }
            }} last_bot={this.props.search.user.last_bot == bot.bot_id ? true : false} data={bot} open={this.state.openBot == bot.bot_id} />);
        });
        return bots;
    };

    renderCollabBots = () => {
        var bots = [];
        if (!this.props.search?.collab_bots) return bots;
        this.props.search.collab_bots.forEach((bot, index) => {
            bots.push(<BotItem index={index} onClick={() => {
                if (this.state.openBot == bot.bot_id) {
                    this.setState({ openBot: "" });
                } else {
                    this.setState({ openBot: bot.bot_id });
                }
            }} last_bot={this.props.search.user.last_bot == bot.bot_id ? true : false} data={bot} open={this.state.openBot == bot.bot_id} />);
        });
        return bots;
    };

    renderSubscriptions = () => {
        var transactions = [];
        if ("transactions" in this.props.search.user && this.props.search.user.transactions.length > 0) {
            this.props.search.user.transactions.forEach(transaction => {
                transactions.push(
                    <tr>
                        <th scope="row"><a href={`https://botghost.chargebee.com/d/subscriptions/${transaction.subscription_id}`} target="_blank">{transaction.subscription_id}</a></th>
                        <td>{transaction.type}</td>
                        <td>{transaction.email}</td>
                        <td>{transaction.active == true ? <span class="badge bg-success">Active</span> : <span class="badge bg-danger">Cancelled</span>}</td>
                        <td><button className="btn btn-sm btn-danger waves-effect waves-light" onClick={() => {
                            this.setState({ subscription: transaction });
                        }} data-bs-toggle="modal" data-bs-target="#moveSubscriptionModal">Move Subscription</button></td>
                    </tr>
                );
            });
        }

        return (
            <tbody>
                {transactions}
            </tbody>);
    };

    renderGifts = () => {
        var gifts = [];
        if (!this.props.search.gifts) return gifts;
        this.props.search.gifts.forEach(gift => {
            gifts.push(
                <tr>
                    <th>{gift.id}</th>
                    <td>{gift.subscription_id}</td>
                    <td>{gift.claimed == true ? "Claimed" : gift.refunded ? "Refunded" : "Unclaimed"}</td>
                    <td>{gift.claimed_by ? gift.claimed_by : "Unclaimed"}</td>
                </tr>
            );
        });
        return gifts;
    };
    render() {
        return (
            <AdminContainer title="Search">
                <AddPremiumModal success={(user) => {
                    var search = { ...this.props.search };
                    search.user = user;
                    this.props.setSearchResults(search);

                }} user_id={this.props.search.user.id} />

                <MoveSubscriptionModal user_id={this.state.user_id} subscription={this.state.subscription} />
                <MoveAllModal user_id={this.state.user_id} />
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='card'>
                            <div class="card-header align-items-center d-flex">
                                <h4 class="card-title mb-0 flex-grow-1">User/Bot Search</h4>

                            </div>

                            <div className='card-body'>
                                <div>
                                    <h5>Search</h5>
                                    <p className='text-muted'>Search for a user or bot. Use either a bot ID, User ID, or Subscription ID/Email.</p>
                                </div>
                                <form>
                                    <div className='gy-4 row'>
                                        <div class="col-xxl-4 col-lg-12">
                                            <div>
                                                <label for="basiInput" class="form-label">Bot ID</label>
                                                <input value={this.state.bot_id} onChange={(e) => {
                                                    this.setState({ bot_id: e.target.value });
                                                }} type="text" placeholder='136327647792726016' class="form-control" id="basiInput" />
                                            </div>
                                        </div>

                                        <div class="col-xxl-4 col-md-6">
                                            <div>
                                                <label for="basiInput" class="form-label">User ID</label>
                                                <input value={this.state.user_id} onChange={(e) => {
                                                    this.setState({ user_id: e.target.value });
                                                }} type="text" placeholder='136327647792726016' class="form-control" id="basiInput" />
                                            </div>
                                        </div>

                                        <div class="col-xxl-4 col-md-6">
                                            <div>
                                                <label for="basiInput" class="form-label">Email</label>
                                                <input value={this.state.email} onChange={(e) => {
                                                    this.setState({ email: e.target.value });
                                                }} type="text" placeholder='tom@botghost.com' class="form-control" id="basiInput" />
                                            </div>
                                        </div>

                                        <div class="col-xxl-4 col-md-4">
                                            <div>
                                                <label for="basiInput" class="form-label">Subscription ID</label>
                                                <input value={this.state.sub_id} onChange={(e) => {
                                                    this.setState({ sub_id: e.target.value });
                                                }} type="text" placeholder='AzywZPT0QPpC067bQ' class="form-control" id="basiInput" />
                                            </div>
                                        </div>
                                        <div class="col-xxl-4 col-md-4">
                                            <div>
                                                <label for="basiInput" class="form-label">Subscription Email</label>
                                                <input value={this.state.sub_email} onChange={(e) => {
                                                    this.setState({ sub_email: e.target.value });
                                                }} type="text" placeholder='tom@botghost.com' class="form-control" id="basiInput" />
                                            </div>
                                        </div>
                                    </div>

                                    <div class="d-flex align-items-start gap-3 mt-4">
                                        <button onClick={() => {
                                            this.setState({
                                                bot_id: "",
                                                user_id: "",
                                                email: "",
                                                sub_id: "",
                                                sub_email: ""
                                            });
                                        }} type="button" class="btn btn-light"> Reset</button>

                                        {this.state.loading == false ? <button onClick={() => {
                                            this.search();
                                        }} type="button" class="btn btn-success btn-label right ms-auto nexttab nexttab" data-nexttab="pills-experience-tab"><i class="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>Search</button>
                                            : <button disabled={true} type="button" class="btn btn-success btn-load right ms-auto nexttab nexttab">
                                                <span class="d-flex align-items-center">
                                                    <span class="flex-grow-1 me-2">
                                                        Searching...
                                                    </span>
                                                    <span class="spinner-border flex-shrink-0" role="status">
                                                        <span class="visually-hidden">Searching...</span>
                                                    </span>
                                                </span>
                                            </button>}

                                    </div>
                                </form>

                            </div>
                        </div>

                    </div>

                    {this.props.search.show == true ?
                        <div>
                            <div className='col-lg-12'>
                                <div className='row'>
                                    <div className='col-lg-6 col-md-12'>
                                        <div class="justify-content-between d-flex align-items-center mt-3 mb-4">
                                            <h5 class="mb-0 pb-1">User Information</h5>
                                        </div>
                                        <div class="card">
                                            <div class="card-body">
                                                <h5 class="card-title mb-3">Info</h5>
                                                <div class="table-responsive">
                                                    <table class="table table-borderless mb-0">
                                                        <tbody>
                                                            <tr>
                                                                <th class="ps-0" scope="row">Name :</th>
                                                                <td class="text-muted">{this.props.search.user.name}</td>
                                                            </tr>
                                                            <tr>
                                                                <th class="ps-0" scope="row">Id :</th>
                                                                <td class="text-muted">{this.props.search.user.id}</td>
                                                            </tr>
                                                            <tr>
                                                                <th class="ps-0" scope="row">Email :</th>
                                                                <td class="text-muted">{this.props.search.user.email}</td>
                                                            </tr>
                                                            <tr>
                                                                <th class="ps-0" scope="row">Tokens :</th>
                                                                <td class="text-muted">{this.props.search.user.tokens || 0}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th class="ps-0" scope="row">Created At</th>
                                                                <td class="text-muted">{moment(this.props.search.user.created_at).fromNow()}</td>
                                                            </tr>

                                                            <tr>
                                                                <th class="ps-0" scope="row">Total Usage</th>
                                                                <td class="text-muted">{this.props.search.user.current_usage || 0}</td>
                                                            </tr>

                                                            <tr>
                                                                <th class="ps-0" scope="row">Last Logged In</th>
                                                                <td class="text-muted">{moment(this.props.search.user.logged).fromNow()}</td>
                                                            </tr>

                                                            <tr>
                                                                <th class="ps-0" scope="row">IP</th>
                                                                <td class="text-muted">{this.props.search.user.ip}</td>
                                                            </tr>

                                                            <tr>
                                                                <th class="ps-0" scope="row">Market Admin</th>
                                                                <td class="text-muted">{this.props.search.user.marketAdmin ? "Yes" : "No"}</td>
                                                            </tr>


                                                            <tr>
                                                                <th class="ps-0" scope="row">Server Premium Claimed</th>
                                                                <td class="text-muted">{this.props.search.user.server_claimed ? "Yes" : "No"}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="card">
                                            <div class="card-body">
                                                <h5 class="card-title mb-3">Actions</h5>
                                                <div className='mb-3'>
                                                    <button type="button" class="btn btn-secondary waves-effect waves-light mr-5" data-bs-toggle="modal" data-bs-target="#addPremiumModal">Add Premium</button>
                                                    <button type="button" class="btn btn-secondary waves-effect waves-light mr-5" style={{ marginLeft: "15px" }} onClick={async () => {
                                                        await server.post("/admin/marketAdmin", {
                                                            user_id: this.props.search.user.id,
                                                            marketAdmin: !this.props.search.user.marketAdmin
                                                        });

                                                        var search = { ...this.props.search };
                                                        search.user.marketAdmin = !search.user.marketAdmin;
                                                        this.props.setSearchResults(search);

                                                    }}>{this.props.search.user.marketAdmin ? "Remove" : "Give"} Market Admin</button>
                                                    <button type="button" class="btn btn-secondary waves-effect waves-light mr-5" data-bs-toggle="modal" data-bs-target="#moveAllModal" style={{ marginLeft: "15px" }}>Transfer Bots + Subscriptions</button>
                                                </div>


                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-lg-6 col-md-12'>
                                        <div class="justify-content-between d-flex align-items-center mt-3 mb-4">
                                            <h5 class="mb-0 pb-1">Subscriptions</h5>
                                        </div>
                                        <table class="table table-dark table-striped table-nowrap">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Subscription ID</th>
                                                    <th scope="col">Type</th>
                                                    <th scope="col">Email</th>
                                                    <th scope="col">Active</th>
                                                    <th scope="col">Move</th>
                                                </tr>
                                            </thead>
                                            {this.renderSubscriptions()}
                                        </table>

                                        <div class="justify-content-between d-flex align-items-center mt-3 mb-4">
                                            <h5 class="mb-0 pb-1">Gifts</h5>
                                        </div>
                                        <table class="table table-dark table-striped table-nowrap">
                                            <thead>
                                                <tr>
                                                    <th scope="col">ID</th>
                                                    <th scope="col">Subscription ID</th>
                                                    <th scope="col">Status</th>
                                                    <th scope="col">Claimed By</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.renderGifts()}
                                            </tbody>
                                        </table>
                                    </div>





                                </div>
                            </div>
                        </div> : null}




                    {/* Bots */}
                    <div className='col-lg-12'>
                        <div class="row">
                            <div class="col-12">
                                <div class="justify-content-between d-flex align-items-center mt-3 mb-4">
                                    <h5 class="mb-0 pb-1">Bots <span className='text-muted'>({this.props.search.bots.length})</span></h5>
                                </div>
                                <div class="accordion custom-accordionwithicon-plus" id="accordionWithplusicon">
                                    {this.renderBots()}
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Collab Bots */}

                    <div className='col-lg-12 mt-3'>
                        <div class="row">
                            <div class="col-12">
                                <div class="justify-content-between d-flex align-items-center mt-3 mb-4">
                                    <h5 class="mb-0 pb-1">Collab Bots <span className='text-muted'>({this.props.search.bots.length})</span></h5>
                                </div>
                                <div class="accordion custom-accordionwithicon-plus" id="accordionWithplusicon">
                                    {this.renderCollabBots()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </AdminContainer>
        );
    }
}

const mapStateToProps = (state) => ({
    search: state.data.search,
    user: state.user,
});

const mapDispatchToProps = {
    setSearchResults
};

export default connect(mapStateToProps, mapDispatchToProps)(Search);